#container {
	position: relative;
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
}

#container.transition .navigation a {
	pointer-events: none;
}

#container #homepage,
#container #subPage {
	position: absolute;
	z-index: 5;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

#container.list #homepage.hidden {
	overflow: hidden;
}

#container.list #homepage:not(.hidden) {
	overflow: scroll;
}

#container #subPage {
	overflow-x: hidden;
	overflow-y: scroll;
	will-change: scroll-position;
	z-index: 4;
}

#container #homepage.hidden,
#container #homepage.hidden * {
	pointer-events: none !important;
}

#container #homepage::-webkit-scrollbar,
#container #subPage::-webkit-scrollbar {
	display: none;
}

#container h1 {
	position: fixed;
	color: white;
	text-transform: uppercase;
	font-size: 6vw;
	width: 200vw;
	height: 200vh;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	white-space: nowrap;
	text-align: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	user-select: none;
}

#container h1.blink {
	animation: blinker 0.15s step-end infinite;
}

@keyframes blinker {
	50% { opacity: 0; }
}

#spiral-template {
	position: absolute;
	width: 100%;
	height: 100%;
	fill: transparent;
	visibility: hidden;
	pointer-events: none;
}

.navigation {
	width: 100%;
	position: fixed;
	padding: calc(5vmin - 16px);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	opacity: 0;
	top: 0;
	pointer-events: none;
}

.navigation a {
	height: 32px;
	line-height: 32px;
	border-radius: 16px;
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: #000;
	background-color: rgba(255, 255, 255, 0.3);
	padding: 0 12px;
	border: none;
	user-select: none;
	cursor: pointer;
	overflow: hidden;
	pointer-events: all;
}

.navigation a:active {
	background-color: rgba(255, 255, 255, 0.5);
}

.navigation a.view-toggle {
	display: flex;
	flex-direction: column;
}

.navigation a.view-toggle span {
	display: block;
	text-align: center;
}

@media only screen and (max-width: 800px) {
	.navigation {
		padding: calc(5vmin - 14px);
	}

	.navigation a {
		height: 28px;
		font-size: 12px;
		padding: 0 10px;
		line-height: 28px;
	}
}

@media only screen and (max-width: 600px) {
	.navigation {
		padding: calc(30px - 14px);
	}
}

@media only screen and (max-width: 540px) {
	.navigation {
		position: absolute;
	}
}