.spiralWrap {
	position: absolute;
	top: 0;
	left: 0;
	user-select: none;
	opacity: 0;
	pointer-events: none;
}

.spiral {
	pointer-events: all;
	transform-origin: center;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}

.spiral.dragging > * {
	cursor: grabbing;
}

.spiral img {
	display: block;
}