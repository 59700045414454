@font-face {
    font-family: 'Nuckle';
    src: url('nuckle_medium-webfont.eot');
    src: url('nuckle_medium-webfont.woff') format('woff'),
    url('nuckle_medium-webfont.woff2') format('woff2'),
    url('nuckle_medium-webfont.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Nuckle';
    src: url('nuckle_semi_bold-webfont.eot');
    src: url('nuckle_semi_bold-webfont.woff') format('woff'),
    url('nuckle_semi_bold-webfont.woff2') format('woff2'),
    url('nuckle_semi_bold-webfont.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TC Numerals';
    src: url('tc-numerals-regular.woff') format('woff'),
    url('tc-numerals-regular.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
