.article-exit {
	opacity: 0;
	transition: opacity var(--transition);
	position: absolute;
	top: 0;
	width: 100%;
}

.button-exit {
	opacity: 0;
	transition: opacity var(--transition);
}

.article h2 {
	font-size: var(--font-size);
	text-transform: uppercase;
}

.article h2 a {
	border-bottom: 1px solid transparent;
}

.article h2 a:hover {
	border-bottom: 1px solid;
}

.page-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	opacity: 0;
	width: fit-content;
}

.full-width-gallery {
	width: var(--page-width);
	flex-shrink: 0;
	border-right: 2px solid rgba(255, 255, 255, 0.1);
}

.content {
	padding: 0 var(--outer-padding);
	color: #fff;
	display: grid;
	grid-column-gap: var(--outer-padding);
	grid-template-columns: 344px 1fr;
	text-transform: none;
	width: var(--page-width);
	flex-shrink: 0;
	position: relative;
	background: #000;
}

.content.disabled,
.full-width-gallery.disabled {
	cursor: pointer;
}

.content.disabled > *,
.full-width-gallery.disabled > * {
	pointer-events: none;
}

.content .title {
	grid-column-start: 1;
	grid-column-end: 2;
}

.content .details {
	margin-bottom: calc(var(--font-size) * var(--line-height));
}

.content p:not(:last-child) {
	margin-bottom: calc(var(--font-size) * var(--line-height));
}

.content a {
	color: inherit;
	border-bottom: 1px solid;
}

.content .sticky {
	position: sticky;
	top: 0;
	padding: var(--outer-padding) 0;
	transform: translate3d(0, 0, 0);
}

.content .process-images {
	grid-column-start: 2;
	grid-column-end: 3;
	display: flex;
	position: sticky;
	height: fit-content;
	top: 0;
}

.content .process-images .column {
	flex: 1;
}

.content .process-images .column:empty {
	display: none;
}

.content .process-image {
	display: flex;
	justify-content: center;
	padding: 5vw 0;
}

.content .process-image:first-child {
	padding-top: 0;
}

.content .process-image:last-child {
	padding-bottom: var(--outer-padding);
}

.content .process-image .image-wrap {
	position: relative;
	background-color: #222;
}

.content .process-image .image-wrap img,
.content .process-image .image-wrap video {
	width: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.content .process-image .caption {
	font-size: 12px;
	color: rgba(255, 255, 255, 0.5);
	margin-top: calc(var(--outer-padding) / 4);
	text-align: left;
}

.back {
	position: fixed;
	width: 10vmin;
	height: 10vmin;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
}

.page-wrap > .back {
	position: absolute;
	z-index: 1;
	display: none;
}

.button {
	width: 32px;
	height: 32px;
	position: absolute;
	top: 50%;
	left: 50%;
	fill: rgba(255, 255, 255, 0.3);
	transform: translate(-50%, -50%);
	pointer-events: all;
}

.button:active {
	fill: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 1280px) {
	.content {
		grid-template-columns: minmax(300px, 26vw) 1fr;
	}
}

@media only screen and (max-width: 1024px) {
	.content {
		grid-template-columns: minmax(300px, 33.5vw) 1fr;
	}
}

@media only screen and (max-width: 760px) {
	.content {
		grid-template-columns: minmax(300px, 344px) 1fr;
	}

	.content .process-images {
		grid-column-start: 1;
		grid-column-end: 3;
		margin-top: 10vmin;
	}

	.button {
		width: 28px;
		height: 28px;
	}
}

@media only screen and (max-width: 540px) {
	:root {
		--page-width: 100vw;
	}

	.page-wrap {
		flex-direction: column;
		transform: translateX(0) !important;
	}

	.full-width-gallery {
		position: relative;
		border-bottom: 2px solid rgba(255, 255, 255, 0.1);
		border-right: none;
	}

	.content {
		grid-template-columns: 1fr;
	}

	.content .process-image,
	.content .process-image:first-child {
		padding: 10vw 0;
	}

	.content .process-image:last-child {
		padding: 10vw 0 20vw;
	}

	.back {
		width: 60px;
		height: 60px;
		display: none;
	}

	.page-wrap > .back {
		display: block;
	}

	.content.disabled,
	.full-width-gallery.disabled {
		cursor: default;
	}

	.content.disabled > *,
	.full-width-gallery.disabled > * {
		pointer-events: all;
	}
}
