.full-width-gallery {
	color: #fff;
	user-select: none;
	position: relative;
	position: sticky;
	top: 0;
	transform: translate3d(0, 0, 0);
}

.full-width-gallery .image-wrap {
	width: 100%;
	height: 100%;
	position: relative;
	opacity: 0;
	will-change: opacity;
	transition: opacity var(--transition);
}

.full-width-gallery .image-wrap > div {
	width: 100%;
	height: 100%;
	position: absolute;
	transform: scale3d(1, 1, 1);
	background-color: #000;
}

.full-width-gallery .image-wrap > div img {
	position: absolute;
	object-fit: contain;
	width: 100%;
	height: 100%;
	padding: 10vmin;
	pointer-events: none;
}

.pager {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 100;
	width: 50%;
}

.pager.next {
	right: 0;
	cursor: e-resize;
}

.pager.previous {
	left: 0;
	cursor: w-resize;
}

.dots {
	display: flex;
	flex-direction: row;
	position: absolute;
	bottom: var(--outer-padding);
	left: 50%;
	transform: translateX(-50%);
}

.dot {
	width: 8px;
	height: 8px;
	margin: 0 4px;
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 50%;
}

#container.touch .pager {
	display: none;
}

#container.touch .full-width-gallery .image-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
}

#container.touch .full-width-gallery .image-wrap::-webkit-scrollbar {
	display: none;
}

#container.touch .full-width-gallery .image-wrap > div {
	position: relative;
	flex-shrink: 0;
	scroll-snap-align: start;
	scroll-snap-stop: normal;
}

@media only screen and (max-width: 800px) {
	.full-width-gallery .image-wrap > div img {
		padding: 80px 10vw;
	}
}

@media only screen and (max-width: 540px) {
	.full-width-gallery .image-wrap > div img {
		padding: 80px var(--outer-padding);
	}

	.dot {
		width: 7px;
		height: 7px;
	}
}