.bounds {
	width: 100%;
	height: 100%;
	position: relative;
	pointer-events: none;
	transition: opacity var(--transition);
	will-change: opacity;
	opacity: 1;
}

.bounds.list {
	height: auto;
}

#container.transition .bounds {
	opacity: 0;
}