.info {
	padding: calc(((5vmin - 16px) * 2) + 32px) calc((5vmin - 16px) + 8px) calc((5vmin - 16px) + 8px);
	opacity: 0;
	color: #fff;
	text-transform: none;
	width: 100%;
	display: block;
}

.info a {
	color: inherit;
	border-bottom: 0.05em solid white;
}

.info .info-section {
	margin-bottom: 4.2vw;
}

.info .justified-text {
	font-size: 3.5vw;
	height: 4.2vw;
	line-height: 1.2;
	text-align: justify;
	text-align-last: justify;
	width: 100%;
	display: block;
}

.info .justified-text::after {
	content: '';
	display: inline-block;
	width:100%;
}

.all-caps {
	text-transform: uppercase;
}

.numerals {
	font-family: "TC Numerals";
}

@media only screen and (max-width: 800px) {
	.info {
		padding: calc(((5vmin - 14px) * 2) + 32px) calc((5vmin - 14px) + 4px) calc((5vmin - 14px) + 4px);
	}
}

@media only screen and (max-width: 600px) {
	.info {
		padding: calc(((30px - 14px) * 2) + 32px) calc((30px - 14px) + 4px) calc((30px - 14px) + 4px);
	}
}

@media only screen and (max-width: 540px) {
	.info-back {
		display: block;
		position: absolute;
	}
}