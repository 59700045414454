.row-container:first-child {
	margin-top: calc(((5vmin - 16px) * 2) + 32px);
	opacity: 0;
}

.row-container {
	opacity: 0;
}

.row {
	padding: 0 calc((5vmin - 16px) + 8px);
	color: #fff;
	text-decoration: none;
	display: grid;
	height: var(--row-height);
	grid-template-columns: 1fr 1fr 1fr min-content;
	grid-column-gap: 0;
	position: relative;
	user-select: none;
	pointer-events: all;
}

.row span {
	border-bottom: 1px solid transparent;
	position: relative;
	z-index: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

#container:not(.touch) .row:hover span {
	border-color: inherit;
}

#root.is-touch .row:hover span {
	border-color: transparent;
}

.row > div {
	display: flex;
	align-items: center;
	z-index: 1;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.title {
	grid-column-start: 1;
	grid-column-end: 2;
}

.type {
	grid-column-start: 2;
	grid-column-end: 3;
}

.client {
	grid-column-start: 3;
	grid-column-end: 4;
}

.year {
	grid-column-start: 4;
	grid-column-end: 5;
}

@media only screen and (min-width: 1680px) {
	:root {
		--row-height: 84px;
	}	
}

@media only screen and (max-width: 1280px) {
	:root {
		--row-height: 64px;
	}	
}

@media only screen and (max-width: 800px) {
	.row-container:first-child {
		margin-top: calc(((5vmin - 14px) * 2) + 32px);
	}

	.row {
		padding: 0 calc((5vmin - 14px) + 4px);
		grid-template-columns: 1fr 1fr min-content;
	}

	.title {
		grid-column-start: 1;
		grid-column-end: 2;
	}

	.type {
		grid-column-start: 2;
		grid-column-end: 3;
	}

	.client {
		display: none !important;
	}

	.year {
		grid-column-start: 3;
		grid-column-end: 4;
	}
}

@media only screen and (max-width: 600px) {
	.row-container:first-child {
		margin-top: calc(((30px - 14px) * 2) + 32px);
	}

	.row {
		padding: 0 calc((30px - 14px) + 4px);
		grid-template-columns: 1fr min-content;
	}

	.title {
		grid-column-start: 1;
		grid-column-end: 2;
}

	.type,
	.client {
		display: none !important;
	}

	.year {
		grid-column-start: 2;
		grid-column-end: 3;
	}
}
