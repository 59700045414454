:root {
  --font-size: 16px;
  --transition: 0.5s ease;
  --row-height: 5vw;
  --outer-padding: 24px;
  --line-height: 1.8;
  --page-peak: 10vmin;
  --page-width: calc(100vw - 10vmin);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body {
  overflow: hidden;
}

body {
  font-family: "Nuckle";
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  color: #fff;
  background-color: #000;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

a {
  text-decoration: none;
}